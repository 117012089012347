
import { React, useState, useEffect, useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import supabase from '../config/supabaseClient'
import Login2 from '../pages/Login2'
import { Spinner } from 'react-bootstrap'
import { UserContext } from '../App2'



const ProtectedRoutes = ({ children }) => {
    const { profile, session } = useContext(UserContext)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return (
        session?.user ?
            <Outlet /> :
            loading ?
                <div className='d-flex justify-content-center'>
                    <Spinner animation="border" role="status" className='m-5' style={{ width: '10rem', height: '10rem' }}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                :
            <Navigate to="/login" replace />
    )
}


export default ProtectedRoutes;