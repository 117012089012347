import { Container, Row, Col, Card, Button, Form, Spinner, Badge } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment'
const AddDoc = () => {
    const navigate = useNavigate()
    const { profile, session } = useContext(UserContext)
    const [docName, setDocName] = useState()
    const [expiry, setExpiry] = useState('')
    const [startDate, setStartDate] = useState('')
    const [images, setImages] = useState([])
    const [notes, setNotes] = useState('')
    const [selectedid, setSelectedId] = useState()
    const [formError, setFormError] = useState()
    const [docTypes, setDocTypes] = useState([])
    const [fileNum, setFileNum] = useState(1)
    const [fileTypes, setFileTypes] = useState(["image/jpg", "application/pdf", "image/png", "image/jpeg"])
    const [selectedType, setSelectedType] = useState('')
    const [loading, setLoading] = useState(false)
    const [missingDocs, setMissingDocs] = useState([])
    const [files, setFiles] = useState([])
    const deleteImages = (urls) => {
        supabase.storage.from('documents').remove(urls)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        // check if images are uploaded
        // if (images.length === 0) {
        //     alert('Please upload images')
        //     return
        // }
        //check if doc name is selected
        if (selectedid === undefined) {
            alert('Please select document name')
            return
        }
        //search for active document with same doc type and user id and set to inactive
        supabase
            .from('documents')
            .update({ active: false })
            // .select('active')
            .match({ user_id: session.user.id, doc_type: selectedid, active: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormError(error.message)
                } else {
                    console.log('updated')
                }
            })
        let tempFiles = []
        if (images.length > 0) {
            //upload images to storage
            for (let i = 0; i < images.length; i++) {
                let file = images[i][0]
                console.log(file)
                let fileObject = {
                    name: session.user.id + "/" + uuidv4(),
                    type: file.type,
                    size: file.size
                }
                supabase.storage.from('documents').upload(fileObject.name, file, { contentType: file.type })
                    .then(({ data, error }) => {
                        if (error) {
                            console.log(error)
                        } else {
                            tempFiles.push(fileObject)
                        }
                    })
            }
            while (tempFiles.length < images.length) {
                await new Promise(r => { setTimeout(r, 500); setLoading(true) });
            }
        }
        setLoading(false)
        supabase
            .from('documents')
            .insert([
                {
                    user_id: session.user.id,
                    doc_type: selectedid,
                    start_date: startDate === '' ? null : startDate,
                    expiry: expiry === '' ? null : expiry,
                    files: tempFiles,
                    doc_notes: notes
                }
            ]).then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormError(error.message)
                    deleteImages(tempFiles)
                } else {
                    navigate('/driver')
                }
            })
    }
    const renderFileUpload = () => {
        let fileUploads = []
        for (let i = 0; i < fileNum; i++) {
            fileUploads.push(
                <Form.Control type="file" placeholder="Select all relevent images"
                    key={i}
                    onChange={(e) => { setImages(current => [...current, e.target.files]); setFileNum(fileNum + 1); }}
                    accept={fileTypes.join(',')}
                    className='my-2'
                />
            )
        }
        return fileUploads
    }
    useEffect(() => {

        supabase
            .from('document_types')
            .select('*')
            .order('order', { ascending: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    setDocTypes(data)
                    let tempDocTypes = data
                    let tempDocs = []
                    supabase
                        .from('documents')
                        .select('*')
                        .eq('user_id', session.user.id)
                        .filter('active', 'eq', true)
                        .then(({ data, error }) => {
                            if (error) {
                                console.log(error)
                            } else {
                                // loop through documents and remove any that are expired from the data array
                                data.forEach(doc => {
                                    if (doc.expiry && moment().isAfter(doc.expiry)) {
                                        data = data.filter(d => d.id !== doc.id)
                                    }
                                })
                                tempDocTypes.forEach(docType => {
                                    if (!data.find(doc => doc.doc_type === docType.id)) {
                                        tempDocs.push(docType)
                                    }
                                })
                                setLoading(false)
                                setMissingDocs(tempDocs)
                            }
                        })
                }
            })
    }, [])

    return (
        <Container>
            <Card className='my-4 shadow'>
                <Card.Body>
                    <Card.Title>Missing Documents</Card.Title>
                    <ul>
                        {
                            missingDocs.length > 0 ? missingDocs.map((doc, index) => {
                                return (
                                    <li key={index}>
                                        {doc.doc_name}
                                        {
                                            doc.required ? <Badge bg='danger'>Required</Badge> : ''
                                        }
                                    </li>
                                )
                            }) : <li>No missing documents</li>
                        }
                    </ul>
                </Card.Body>
            </Card>
            <Card className='my-4 shadow'>
                <Card.Body>
                    <Card.Title>Add Document</Card.Title>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="dname">
                            <Form.Label>Document Name</Form.Label>
                            <Form.Select onChange={(e) => { setSelectedId(e.target.selectedOptions[0].attributes['akey'].value) }}>
                                <option>Select from list</option>
                                {
                                    docTypes.map((docType) => {
                                        return (
                                            <option key={docType.id} akey={docType.id} variant='outline-primary' className='m-1' onSelect={() => console.log('select')} onClick={(e) => setDocName(docType)}>{docType.doc_name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="img">
                            <Form.Label>Select Files</Form.Label>
                            {renderFileUpload()}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="notes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Text className="text-danger">
                            {formError}
                        </Form.Text>
                        <Button className='my-3 float-end' variant="primary" type='submit'>
                            Submit
                        </Button>
                        {
                            loading && <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default AddDoc