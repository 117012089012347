import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import supabase from '../config/supabaseClient'
import { UserContext } from '../App2'
import { useForm } from 'react-hook-form'
const Registration = () => {
	const { profile, session } = useContext(UserContext)
	const [fname, setFname] = useState('')
	const [lname, setLname] = useState('')
	const [phone, setPhone] = useState('')
	const [id, setid] = useState()
	const [vehicle_reg, setVehicle_reg] = useState('')
	const [vehicle_make, setVehicle_make] = useState('')
	const [vehicle_model, setVehicle_model] = useState('')
	const [formerror, setFormerror] = useState('')
	const navigate = useNavigate()
	const { register, handleSubmit, formState: { errors }, setValue } = useForm();
	const getids = async () => {
		await supabase
			.from('all_user_ids')
			.select('user_simple_id')
			.then(({ data, error }) => {
				if (error) {
					console.log(error)
				} else {
					let maxValue = data.reduce((max, current) => {
						return Math.max(max, current.user_simple_id);
					}, 0);
					maxValue += 1
					setid(maxValue)
					console.log(maxValue)
					return maxValue
				}
			})
	}

	const handleCreate = async (form) => {
		await supabase
			.from('all_user_ids')
			.select('user_simple_id')
			.order('user_simple_id', { ascending: true })
			.then(({ data, error }) => {
				if (error) {
					console.log(error)
				} else {
					let lowest = -1
					if (data.length == 0) {
						lowest = 0 
					} else {
						for (let i = 0; i < data.length; i++) {
							if (data[i].user_simple_id != i) {
								lowest = i
								break
							}
						}
						if (lowest == -1) {
							lowest = data[data.length - 1].user_simple_id + 1
						}
					}
					supabase
						.from("user_profiles")
						.insert([
							{
								user_id: session.user.id,
								user_simple_id: lowest,
								fname: form.fname,
								lname: form.lname,
								email: session.user.email,
								phone: form.phone
							},
						])
						.then(({ error }) => {
							if (error) {
								console.log(error)
								setFormerror(error.message)
								if (error.code === '23503') {
									supabase.auth.signOut()
								}
							}
							else {
								supabase
									.from("vehicle")
									.insert([
										{
											user_id: session.user.id,
											reg: form.reg,
											make: form.make,
											model: form.model,
										},
									])
									.then(({ error }) => {
										if (error) {
											console.log(error)
											setFormerror(error.message)
										}
										else {
											return
										}
									});
								navigate('/driver');
							}
						});
				}
			})



	}
	return (
		// create form for user to enter their information using react-bootstrap
		<Container>
			<h3 className='my-5'>Welcome, please enter your details</h3>
			<Card>
				<Card.Body>
					<p>{formerror}</p>
					<Form onSubmit={handleSubmit(handleCreate)}>
						<Form.Group className='mb-3' controlId='formFname'>
							<Form.Label>First Name</Form.Label>
							<Form.Control type='text' placeholder='Enter First Name' 
							{...register('fname', { required: true })}  
							isInvalid={errors.fname}
							/>
							<Form.Control.Feedback type='invalid'>
								Please enter your first name
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formLname'>
							<Form.Label>Last Name</Form.Label>
							<Form.Control type='text' placeholder='Enter Last Name'  
							{...register('lname', { required: true })} 
							isInvalid={errors.lname}
							/>
							<Form.Control.Feedback type='invalid'>
								Please enter your last name
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mb-3' controlId='email'>
							<Form.Label>Email</Form.Label>
							<Form.Control type='email' placeholder='Enter Email' value={session?.user.email} disabled />
						</Form.Group>
						<Form.Group className='mb-3' controlId='formPhone'>
							<Form.Label>Phone</Form.Label>
							<Form.Control type='text' placeholder='Enter Phone Number'
							 {...register('phone', { required: true, maxLength: 12, pattern: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i })}
							 isInvalid={errors.phone}
							/>
							<Form.Control.Feedback type='invalid'>
								Please enter a valid UK phone number
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formVehicleReg'>
							<Form.Label>Vehicle Registration (No Spaces)</Form.Label>
							<Form.Control type='text' placeholder='Enter Vehicle Registration'  
							{...register('reg', { required: true, maxLength: 15 })}
							isInvalid={errors.reg}
							/>
							<Form.Control.Feedback type='invalid'>
								Please enter a valid UK vehicle registration
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formVehicleMake'>
							<Form.Label>Vehicle Make</Form.Label>
							<Form.Control type='text' placeholder='Enter Vehicle Make' 
							{...register('make', { required: true })}
							isInvalid={errors.make}
							/>
							<Form.Control.Feedback type='invalid'>
								Please enter your vehicle make
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formVehicleModel'>
							<Form.Label>Vehicle Model</Form.Label>
							<Form.Control type='text' placeholder='Enter Vehicle Model' 
							{...register('model', { required: true })}
							isInvalid={errors.model}
							/>
							<Form.Control.Feedback type='invalid'>
								Please enter your vehicle model
							</Form.Control.Feedback>
						</Form.Group>
						<Button className='float-end' variant="primary" type='submit'>Submit</Button>
					</Form>
				</Card.Body>
			</Card>
		</Container>
	)
}

export default Registration