import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import ChecklistModal from '../ChecklistModal'
import moment from 'moment/moment'
const FilterableTable = require('react-filterable-table');

const ViewChecklist = (props) => {
    const { profile, session } = useContext(UserContext)
    const navigate = useNavigate()
    const [res, setRes] = useState(null)
    const [defects, setDefects] = useState(null)
    const [vehicle_reg, setVehicleReg] = useState('')
    const [created, setCreated] = useState('')
    const [showChecklistModal, setShowChecklistModal] = useState(false)
    const [selectedChecklist, setSelectedChecklist] = useState()
    const renderModalButton = (props) => {
        return (
            <div>
            <Button variant="primary" onClick={() => { setShowChecklistModal(true); setSelectedChecklist(props.record) }}>
                View
            </Button>
            </div>
        )
    }
    const renderDefects = (props) => {
        const falseChecklist = Object.keys(props.record.checklist).filter(key => props.record.checklist[key] === false)
        return (
            <ul>
                {falseChecklist.map((defect) => {
                    return (
                        <li key={defect} className='mx-2'>
                            {defect}
                        </li>
                    )})}
            {props.record.defects}
            </ul>
        )

    }
    const renderDate = (props) => {
        return (
            <div>
                {moment(props.record.created_at).format('DD-MM-YYYY')}
            </div>
        )
    }
    const fields = [
        { name: 'created_at', displayName: "Date", inputFilterable: true, exactFilterable: true, sortable: true, render: renderDate },
        { name: 'vehicle_reg', displayName: "Reg", inputFilterable: true, exactFilterable: true, sortable: false },
        { name: 'defects', displayName: "Defects", inputFilterable: true, exactFilterable: false, sortable: false, render: renderDefects },
        { name: 'user_id', displayName: "Actions", inputFilterable: false, exactFilterable: false, sortable: false, render: renderModalButton }
    ];
    useEffect(() => {
        supabase
            .from('checklist')
            .select('*')
            .eq('user_id', props.user_id)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setRes(data)
                }
            })
    }, [])
    return (
        <Container>
            {showChecklistModal ? <ChecklistModal item={selectedChecklist} show={showChecklistModal} onHide={() => { setShowChecklistModal(false); setSelectedChecklist(null) }} />: null }
            <FilterableTable
                namespace="Checklists"
                initialSort="created_at"
                initialSortDir={false}
                data={res ? res : []}
                fields={fields}
                noRecordsMessage="There are no checklists to display"
                noFilteredRecordsMessage="No checklists match your filters!"
                loadingMessage="Loading checklists..."
                pageSizes={[5, 10, 20, 50, 100]}
                topPagerVisible={false}
                bottomPagerVisible={true}
                tableClassName="table table-striped table-bordered table-hover shadow rounded"
            />
        </Container>
    )
}

export default ViewChecklist
