import { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { useNavigate, redirect, Outlet, useLocation } from "react-router-dom"
import { Spinner } from 'react-bootstrap'
import supabase from "../config/supabaseClient"
import { UserContext } from '../App2'
const Redirector = () => {
    const { profile, session } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    let location = useLocation()

    useEffect(() => {
        if (profile?.isadmin) {
            navigate('/admin', { replace: true })
        } else {
            navigate('/driver', { replace: true })
        }
    }, [])
}

export default Redirector