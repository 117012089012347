import { Button, Container, Nav, Card, Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation, Route } from 'react-router-dom'
import supabase from "../../config/supabaseClient"
import EditProfile from './driver/EditProfile'
import ViewChecklist from './driver/ViewChecklists'
import ViewDocs from './driver/ViewDocs'
import EditVehicle from './driver/EditVehicle'

const EditDriver = (props) => {
	const [view, setView] = useState(props.view)

	const { user_id } = useParams()

	const navigate = useNavigate()
	const location = useLocation();

	useEffect(() => {
		setView(location.state.view)

	}, [location])
	return (

		<div >
			<Row className='p-2 py-3 text-center'>
				<Col sm={6}>
					<h1 >Edit Driver</h1>
				</Col>
				<Col sm={6} >
					<Button variant='outline-primary' className='m-2' onClick={() => navigate(-1)}>Go Back</Button>
				</Col>
			</Row>
			<Container>
				<Row className="justify-content-md-center">
					<Col sm='auto'>
						<Card className='shadow'>
							<Card.Body>
								<Nav className="justify-content-center" variant="pills" defaultActiveKey={location.state.view}>
									<Nav.Item>
										<Nav.Link eventKey="profile" onClick={() => setView('profile')} >Profile</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="vehicle" onClick={() => setView('vehicle')}>Vehicle</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="checklists" onClick={() => setView('checklists')}>Checklists</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="docs" onClick={() => setView('docs')}>Documents</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<div className='my-3'>
				{
					view === 'profile' ? <EditProfile user_id={user_id} /> :
						view === 'vehicle' ? <EditVehicle user_id={user_id} /> :
							view === 'checklists' ? <ViewChecklist user_id={user_id} /> :
								view === 'docs' ? <ViewDocs user_id={user_id} />
									: null
				}
			</div>
		</div>
	)
}



export default EditDriver