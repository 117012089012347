import { Card, Button, Stack, Badge } from 'react-bootstrap'
import { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate, Navigate } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import moment from 'moment/moment'
const DocsCard = (props) => {
    const navigate = useNavigate()
    const [docs, setDocs] = useState([])
    const [loading, setLoading] = useState(true)
    const [missingDocs, setMissingDocs] = useState(0)
    
    useEffect(() => {
            supabase
                .from('document_types')
                .select('*')
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    } else {
                        if (data.length === 0) {
                            console.log('no doc types')
                            return
                        }
                        let docTypes = data
                        supabase
                            .from('documents')
                            .select('*')
                            .eq('user_id', props.user_id)
                            .filter('active', 'eq', true)
                            .then(({ data, error }) => {
                                if (error) {
                                    console.log(error)
                                } else {
                                    if (data.length === 0) {
                                        console.log('no docs')
                                        setLoading(false)
                                        setMissingDocs(docTypes.length)
                                        return
                                    }
                                    setDocs(data)
                                    let missingDocs = 0
                                    // loop through documents and remove any that are expired from the data array
                                    data.forEach(doc => {
                                        if (doc.expiry && moment().isAfter(doc.expiry)) {
                                            data = data.filter(d => d.id !== doc.id)
                                        }
                                    })
                                    docTypes.forEach(docType => {
                                        if (!data.find(doc => doc.doc_type === docType.id)) {
                                            missingDocs++
                                        }
                                    })
                                    setMissingDocs(missingDocs)
                                    setLoading(false)
                                }
                            })
                    }
                })
    }, [props.user_id])
    return (
        <Card className="h-100 shadow">
            <Card.Body>
                <Card.Title>Documents</Card.Title>
                    {
                        loading ? <p>Loading...</p> :
                        <>
                            {missingDocs < 1 ? <p>All documents are up to date</p> : 
                            <>
                                <p>Missing documents <Badge bg='warning'>{missingDocs}</Badge></p>
                            </>
                            }
                        </>
                    }
                <Stack gap={1} className='justify-content-center'>
                <Button variant="success" onClick={() => navigate('/driver/newdocument')}>
                    Add Document
                </Button>
                </Stack>
            </Card.Body>
            <Card.Footer className='text-end'>
                <Button variant="link" onClick={() => navigate('/driver/viewdocuments')}>
                    View Documents
                </Button>
            </Card.Footer>
        </Card>
    )
}

export default DocsCard

