import React from 'react';
import { Link } from "react-router-dom";
import { useContext } from 'react';
import { Nav, Navbar, Container, Button } from "react-bootstrap";
import { UserContext } from '../App2';
import supaClient from "../config/supabaseClient";

const NavbarComponent = () => {
    const {profile, session} = useContext(UserContext);

    return (
        <div>
            <header>
                <Navbar bg="dark" variant="dark" className='shadow-sm'>
                    <Container fluid>
                            {session ?
                                <>
                                    {
                                        profile ?
                                                profile.isadmin ?
                                                <Nav>
                                                    <Nav.Link as={Link} to="/admin/drivers">
                                                        Drivers
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to="/admin/checklists">
                                                        Chekclists
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to="/admin/documents">
                                                        Documents
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to="/admin/expiring-documents">
                                                        Expiring Documents
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to="/admin/recent-documents">
                                                        Recent Documents
                                                    </Nav.Link>

                                                </Nav>
                                                :
                                                    <Nav.Link as={Link} to="/driver">
                                                        <Button variant='light'>Home</Button>
                                                    </Nav.Link>
                                        : <></>
                                    }
                                <Nav className='justify-content-end end-0'>
                                    <Nav.Link onClick={() => supaClient.auth.signOut()} >
                                        <Button variant='outline-danger'>Sign Out</Button>
                                    </Nav.Link>
                                </Nav>
                                </>
                                :
                                <></>
                            }
                    </Container>
                </Navbar>
            </header>

        </div>
    );
};

export default NavbarComponent;