import { Button, Modal, Container } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import supabase from "../../../config/supabaseClient"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ImageModal = (props) => {
    const [images, setImages] = useState([]);
    const [fileType, setFileType] = useState('')
    const [pageNumber, setPageNumber] = useState(1);
    const [pages, setPages] = useState([]);
    const [notes, setNotes] = useState('');

    const pageSetup = (numPages, index) => {
        let newPages = {
            [index]: {numPages: numPages,
            pageNumber: 1}
        };
        setPages({...pages, ...newPages});
    }
    function handleNext(index) {
        let page = pages[index].pageNumber;
        let numPages = pages[index].numPages;
        if (page < numPages) {
            let newPages = {
                [index]: {numPages: numPages,
                pageNumber: page + 1}
            };
            setPages({...pages, ...newPages});
        }
    }
    function handlePrev(index) {
        let page = pages[index].pageNumber;
        let numPages = pages[index].numPages;
        if (page > 1) {
            let newPages = {
                [index]: {numPages: numPages,
                pageNumber: page - 1}
            };
            setPages({...pages, ...newPages});
        }
    }

    useEffect(() => {
        async function getImages(urls) {
            let ims = [];
            for (let i = 0; i < urls.length; i++) {
                let tempFileObj = {
                    name: urls[i].name,
                    size: urls[i].size,
                    type: urls[i].type
                }
                const { data, error } = await supabase
                    .storage
                    .from('documents')
                    .createSignedUrl(urls[i].name, 120);
                if (data !== null) {
                    tempFileObj.signedUrl = data.signedUrl;
                    ims.push(tempFileObj)
                } else {
                    console.log(error)
                }
            }
            setImages(ims);
        }

        getImages(props.files);
        setFileType(props.file_type)
    }, [props])
    return (
        <Modal show={props.show} onHide={props.onHide} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Document Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container >
                    {
                        images.length > 0 ?
                            images.map((image, index) => {
                                if (image.type === 'application/pdf') {
                                    return (
                                        <div key={index} className='border rounded my-1'>
                                            <p>File: {index + 1} of {images.length}</p>
                                            <Document
                                                file={image.signedUrl}
                                                onLoadSuccess={({numPages}) => {pageSetup(numPages, index)}}
                                            >
                                                <Page pageNumber={pages[index] ? pages[index].pageNumber : 1} />
                                            </Document>
                                            <div className='d-flex justify-content-center'>
                                                <Button variant='primary' onClick={() => handlePrev(index)}>Previous Page</Button>
                                                <p className='m-2'>Page {pages[index] ? pages[index].pageNumber : 1} of {pages[index] ? pages[index].numPages : 1}</p>
                                                <Button variant='primary' onClick={() => handleNext(index)}>Next Page</Button>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <Button variant='link' href={image.signedUrl} target='_blank' className='text-center'>Open in new tab</Button>
                                            </div>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index}>
                                            <p>File: {index + 1} of {images.length}</p>
                                            <img src={image.signedUrl} className='w-25 m-1' />
                                        </div>
                                    )
                                }
                            })
                            : <p>No Images</p>
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImageModal