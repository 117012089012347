import { Button, Modal, Form, Container } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import supabase from "../../../config/supabaseClient"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const DeleteDocModal = (props) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const nav = useNavigate()
    const handleDelete = async () => {
        supabase.storage.from('documents').remove(props.item.image_urls)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    console.log(data)
                    supabase
                        .from('documents')
                        .delete()
                        .match({ id: props.item.id })
                        .then(({ data, error }) => {
                            if (error) {
                                console.log(error)
                            } else {
                                handleClose()
                                nav(0)
                            }
                        })
                }
            })
    }
    return (
        <>
            <Button variant="danger" onClick={handleShow}>
                Delete
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this document?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const DocEditModal = (props) => {
    const [show, setShow] = useState(false)
    const [doc, setDoc] = useState({})
    const [selectedid, setSelectedId] = useState(props.item.doc_type)
    const [expiry, setExpiry] = useState('')
    const [startDate, setStartDate] = useState('')
    const [formError, setFormError] = useState()
    const [docName, setDocName] = useState('')
    const [docTypes, setDocTypes] = useState([])
    const [images, setImages] = useState([])
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileType, setFileType] = useState('')
    const [pages, setPages] = useState([])

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const pageSetup = (numPages, index) => {
        let newPages = {
            [index]: {numPages: numPages,
            pageNumber: 1}
        };
        setPages({...pages, ...newPages});
    }
    function handleNext(index) {
        let page = pages[index].pageNumber;
        let numPages = pages[index].numPages;
        if (page < numPages) {
            let newPages = {
                [index]: {numPages: numPages,
                pageNumber: page + 1}
            };
            setPages({...pages, ...newPages});
        }
    }
    function handlePrev(index) {
        let page = pages[index].pageNumber;
        let numPages = pages[index].numPages;
        if (page > 1) {
            let newPages = {
                [index]: {numPages: numPages,
                pageNumber: page - 1}
            };
            setPages({...pages, ...newPages});
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        supabase
            .from('documents')
            .update(
                {
                    doc_type: selectedid,
                    expiry: expiry === null ? null : expiry,
                    start_date: startDate === null ? null : startDate,
                }
            )
            .match({ id: props.item.id })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormError(error.message)
                } else {
                    props.onHide()
                }
            })
    }

    useEffect(() => {
        setExpiry(props.item.expiry)
        setDoc(props.item)
        setFileType(props.item.file_type)
        setStartDate(props.item.start_date)
        supabase
            .from('document_types')
            .select('*')
            .order('order', { ascending: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    setDocTypes(data)
                }
            })
        async function getImages(urls) {
            let ims = [];
            for (let i = 0; i < urls.length; i++) {
                let tempFileObj = {
                    name: urls[i].name,
                    size: urls[i].size,
                    type: urls[i].type
                }
                const { data, error } = await supabase
                    .storage
                    .from('documents')
                    .createSignedUrl(urls[i].name, 120);
                if (data !== null) {
                    tempFileObj.signedUrl = data.signedUrl;
                    ims.push(tempFileObj)
                } else {
                    console.log(error)
                }
            }
            setImages(ims);
        }
        getImages(props.item.files);
    }, [props])

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Edit
            </Button>

            <Modal show={props.show} onHide={props.onHide} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="dname">
                            <Form.Label>Document Name</Form.Label>
                            <select onChange={(e) => { setSelectedId(e.target.value) }} value={selectedid}>
                                <option>Select from list</option>
                                {
                                    docTypes.map((docType) => {
                                        return (
                                            <option value={docType.id} key={docType.id} akey={docType.id} variant='outline-primary' className='m-1' onClick={(e) => { setDocName(docType); setSelectedId(docType.id) }}>{docType.doc_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="sdate">
                            <Form.Label>Start Date (leave blank if not applicable)</Form.Label>
                            <Form.Control type="date" defaultValue={doc.start_date} onChange={(e) => setStartDate(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="expiry">
                            <Form.Label>Expiry Date (leave blank if not applicable)</Form.Label>
                            <Form.Control type="date" defaultValue={doc.expiry} onChange={(e) => setExpiry(e.target.value)} />
                        </Form.Group>
                        <Container >
                    {
                        images.length > 0 ?
                            images.map((image, index) => {
                                if (image.type === 'application/pdf') {
                                    return (
                                        <div key={index} className='border rounded my-1'>
                                            <p>File: {index + 1} of {images.length}</p>
                                            <Document
                                                file={image.signedUrl}
                                                onLoadSuccess={({numPages}) => {pageSetup(numPages, index)}}
                                            >
                                                <Page pageNumber={pages[index] ? pages[index].pageNumber : 1} />
                                            </Document>
                                            <div className='d-flex justify-content-center'>
                                                <Button variant='primary' onClick={() => handlePrev(index)}>Previous Page</Button>
                                                <p className='m-2'>Page {pages[index] ? pages[index].pageNumber : 1} of {pages[index] ? pages[index].numPages : 1}</p>
                                                <Button variant='primary' onClick={() => handleNext(index)}>Next Page</Button>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <Button variant='link' href={image.signedUrl} target='_blank' className='text-center'>Open in new tab</Button>
                                            </div>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index}>
                                            <p>File: {index + 1} of {images.length}</p>
                                            <img src={image.signedUrl} className='w-25 m-1' />
                                        </div>
                                    )
                                }
                            })
                            : <p>No Images</p>
                    }
                </Container>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onHide}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit' >
                                Save Changes
                            </Button>
                            <DeleteDocModal show={show} onHide={handleClose} closeMain={handleClose} item={props.item} />
                        </Modal.Footer>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default DocEditModal