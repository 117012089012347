import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import ViewFullChecklist from './ViewFullChecklist'

const ViewChecklist = () => {
    const {profile, session} = useContext(UserContext)
    const navigate = useNavigate()
    const [res, setRes] = useState(null)
    const [defects, setDefects] = useState(null)
    const [vehicle_reg, setVehicleReg] = useState('')
    const [created, setCreated] = useState('')
    const [showChecklistModal, setShowChecklistModal] = useState(false)
    const [selectedChecklist, setSelectedChecklist] = useState(null)

    useEffect(() => {
        supabase
            .from('checklist')
            .select('*')
            .eq('user_id', session.user.id)
            .order('created_at', { ascending: false })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setRes(data)
                }
            })
    }, [])
    return (
        <Container>
            {showChecklistModal ? <ViewFullChecklist item={selectedChecklist} show={showChecklistModal} onHide={() => { setShowChecklistModal(false); setSelectedChecklist(null) }} /> : null}
            <h1 className='display-4 text-center my-3'>Checklists</h1>
            <Table striped bordered hover className='shadow'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Vehicle Reg</th>
                        <th>Defects</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {res && res.map((item) => {
                        const falseChecklist = Object.keys(item.checklist).filter(key => item.checklist[key] === false)
                        return (
                            <tr key={item.id}>
                                <td>{item.created_at.split('-').reverse().join('-')}</td>
                                <td>{item.vehicle_reg}</td>
                                <td>
                                    {falseChecklist.map((defect) => {
                                            return (
                                                <li key={defect} className='mx-4'>
                                                {defect}
                                            </li>
                                        )
                                    
                                    })}
                                    {item.defects ? item.defects : <p>No defects</p>}
                                </td>
                                <td>
                                    <Button variant='primary' onClick={() => { setSelectedChecklist(item); setShowChecklistModal(true) }}>View</Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    )
}

export default ViewChecklist
