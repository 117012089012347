import { Container, Row, Col, Card, Button, Form, ListGroup } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { Navigate, Outlet, useNavigate } from "react-router-dom"
import supabase from "../../config/supabaseClient"
import { UserContext } from '../../App2'

import ProfileCard from './profile/ProfileCard'
import VehicleCard from './vehicle/VehicleCard'
import DocsCard from './docs/DocsCard'
import ChecklistCard from './checklist/ChecklistCard'

const Home2 = () => {
    const { profile, session } = useContext(UserContext)
    const navigate = useNavigate()
    return (
        profile?.isadmin ? <Navigate to='/admin/drivers' /> :
            <Container>
                <h5 className='display-4 text-center my-3'>Home</h5>
                <Row>
                    <Col xs={12} md={3} className='py-2'>
                    <ProfileCard user_id={session.user.id} />
                    </Col>
                    <Col xs={12} md={3} className='py-2'>
                        <VehicleCard user_id={session.user.id}/>
                    </Col>
                    <Col xs={12} md={3} className='py-2'>
                        <ChecklistCard user_id={session.user.id}/>
                    </Col>
                    <Col xs={12} md={3} className='py-2'>
                        <DocsCard user_id={session.user.id}/>
                    </Col>
                </Row>
                <Outlet />
            </Container>
    )
}

export default Home2