import React, { useState, useEffect } from 'react'
import supabase from '../../../config/supabaseClient'
import { Button, Form, Row, Col, Card, Alert, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

const DeleteModal = (props) => {
    const navigate = useNavigate()
    const handleDelete = () => {
        console.log(props.id)
        supabase
            .from('vehicle')
            .delete()
            .match({ id: props.id })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    navigate(0)
                }
            })
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Vehicle
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to delete this vehicle?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
    )
}

const EditVehicle = (props) => {
    const [id, setId] = useState('')
    const [reg, setReg] = useState('')
    const [make, setMake] = useState('')
    const [model, setModel] = useState('')

    const [changed, setChanged] = useState(false)
    const [formerror, setFormerror] = useState('')
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const handleUpdate = data => {
        supabase
            .from('vehicle')
            .update([
                {
                    reg: data.reg,
                    make: data.make,
                    model: data.model,
                },
            ])
            .match({ id: id })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormerror(error.message)
                } else {
                    setChanged(false)
                    setShowSuccess(true)
                }
            })
            setTimeout(() => {
                setShowSuccess(false)
            }, 3000)
    }
    const handleCreate = data => {
        supabase
            .from('vehicle')
            .insert([
                {
                    reg: data.reg,
                    make: data.make,
                    model: data.model,
                    user_id: props.user_id,
                },
            ]).then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormerror(error.message)
                } else {
                    setChanged(false)
                    setShowSuccess(true)
                    navigate(0)
                }
            })
            setTimeout(() => {
                setShowSuccess(false)
            }, 3000)
    }



    useEffect(() => {
        supabase
            .from('vehicle')
            .select('*')
            .eq('user_id', props.user_id)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    const vehicle = data[0]
                    if (vehicle) {
                        setValue('reg', vehicle.reg)
                        setValue('make', vehicle.make)
                        setValue('model', vehicle.model)
                        setId(vehicle.id)
                    } else {
                        setId('')
                    }
                }
            })
    }, [props])

    return (
        <Row className='justify-content-lg-center'>
            <Col md={5}>
                <DeleteModal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} id={id} />
                <Card className='shadow'>
                    <Card.Body>
                        <>
                            {id ?
                                <Form onSubmit={handleSubmit(handleUpdate)}>
                                    <Form.Group className="mb-3" controlId="reg">
                                        <Form.Label>Registration Number</Form.Label>
                                        <Form.Control type="text" placeholder="Enter registration number"
                                            {...register('reg', { required: true, maxLength: 10 })}
                                            isInvalid={errors.reg}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid registration number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="make">
                                        <Form.Label>Make</Form.Label>
                                        <Form.Control type="text" placeholder="Enter make"
                                            {...register('make', { required: true, maxLength: 20 })}
                                            isInvalid={errors.make}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid make
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="model">
                                        <Form.Label>Model</Form.Label>
                                        <Form.Control type="text" placeholder="Enter model"
                                            {...register('model', { required: true, maxLength: 20 })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid model
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button variant="primary" type="submit" >
                                        Update
                                    </Button>
                                    <Button variant="danger" type="button" onClick={() => setDeleteConfirm(true)}>
                                        Delete
                                    </Button>
                                </Form>
                                :
                                <Form onSubmit={handleSubmit(handleCreate)}>
                                    <p>User has not set a vehicle</p>
                                    <Form.Group className="mb-3" controlId="reg">
                                        <Form.Label>Registration Number</Form.Label>
                                        <Form.Control type="text" placeholder="Enter registration number"
                                            {...register('reg', { required: true, maxLength: 10 })}
                                            isInvalid={errors.reg}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid registration number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="make">
                                        <Form.Label>Make</Form.Label>
                                        <Form.Control type="text" placeholder="Enter make"
                                            {...register('make', { required: true, maxLength: 20 })}
                                            isInvalid={errors.make}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid make
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="model">
                                        <Form.Label>Model</Form.Label>
                                        <Form.Control type="text" placeholder="Enter model"
                                            {...register('model', { required: true, maxLength: 20 })}
                                            isInvalid={errors.model}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid model
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button variant="primary" type='submit'>Add</Button>
                                </Form>
                            }
                        </>
                    </Card.Body>
                    {showSuccess && <Alert variant='success'>Vehicle updated</Alert>}
                </Card>
            </Col>
        </Row>
    )
}

export default EditVehicle