import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import moment from 'moment/moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Checklist = () => {
    const navigate = useNavigate()
    const {profile, session} = useContext(UserContext)
    const [allChecked, setAllChecked] = useState(false)
    const [vehicle_reg, setVehicleReg] = useState('')
    const [created, setCreated] = useState(moment().format('YYYY-MM-DD'))
    const [defects, setDefects] = useState(null)
    const [formErrors, setFormErrors] = useState(null)

    
    function checkAllBoxes() {
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        checkboxes.forEach((checkbox) => {
            checkbox.checked = true
        })
        setAllChecked(true)
    }
    const handleCheckboxes = (e) => {
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        const checked = []
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                checked.push(checkbox.value)
            }
        })
        if (checked.length === checkboxes.length) {
            setAllChecked(true)
        } else {
            setAllChecked(false)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        const checklist = {}
        checkboxes.forEach((checkbox) => {
            checklist[checkbox.name] = checkbox.checked
        })
        supabase
        .from('checklist')
        .select('id')
        .eq('user_id', session.user.id)
        .eq('created_at', created)
        .then(({ data, error }) => {
            if (error) {
                console.log(error)
            }
            else {
                console.log(data)
                if (data.length > 0) {
                    setFormErrors('Checklist already exists for this date')
                    return
                } else {
                    supabase
                        .from('checklist')
                        .insert([
                            {
                                user_id: session.user.id,
                                vehicle_reg: vehicle_reg,
                                created_at: created,
                                checklist: checklist,
                                defects: defects
                            },
                        ])
                        .then(({ error }) => {
                            if (error) {
                                console.log(error)
                            } else {
                                navigate('/')
                            }
                        })
                }
            }
        })
    }
    useEffect(() => {
        supabase
            .from('vehicle')
            .select('reg')
            .eq('user_id', session.user.id)
            .then(({data, error}) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setVehicleReg(data[0].reg)
                }
            })
    }, [])
    return (
        <Container>
            <Card className='my-4 shadow'>
                <Card.Body>
                    <Card.Title>Daily Checklist</Card.Title>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="date">
                            <Form.Label>Date</Form.Label>
                            <Form.Control type="date" placeholder="Enter date" 
                                defaultValue={created} onChange={(e) => setCreated(e.target.value)}  
                                max={moment().format('YYYY-MM-DD')}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="vehicleReg">
                            <Form.Label>Vehicle Registration</Form.Label>
                            <Form.Control type="text" placeholder="Enter vehicle registration" defaultValue={vehicle_reg} onChange={(e) => setVehicleReg(e.target.value)} />
                        </Form.Group>
                        <Button className='my-3' variant="outline-primary" onClick={checkAllBoxes}>
                            Mark all as complete
                        </Button>
                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" name="Body Damage" label="Body Damage" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Windscreen" label="Windscreen" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Side / rear windows" label="Side / rear windows" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Door functions" label="Door functions" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Wheel nuts" label="Wheel nuts" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Tyres" label="Tyres" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Oil level" label="Oil level" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Water level" label="Water level" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Mirrors" label="Mirrors" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Fuel level" label="Fuel level" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Wiper & washers" label="Wiper & washers" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Indicators" label="Indicators" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Side / head lights" label="Side / head lights" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Interior lights" label="Interior lights" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Brake lights" label="Brake lights" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Steering" label="Steering" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Brakes" label="Brakes" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Seat belts" label="Seat belts" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Horn" label="Horn" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Fluid leaks" label="Fluid leaks" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Exhast" label="Exhast" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Fire extinguisher" label="Fire extinguisher" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="First aid box" label="First aid box" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Door signs" label="Door signs" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Taxi plates" label="Taxi plates" onChange={(e) => handleCheckboxes(e)} />
                        </Form.Group>
                        {!allChecked ?
                            <Form.Group className="mb-3" controlId="defects">
                                <Form.Label>Defects</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(e) => setDefects(e.target.value)} placeholder='Please add defect details ' />
                            </Form.Group> : null
                        }
                        {formErrors ?
                            <Alert variant='danger'>
                                {formErrors}
                            </Alert> : null
                        }
                        <Button className='my-3 float-end' variant="primary" type='submit'>
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Checklist