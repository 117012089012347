import { Container, Row, Col, Card, Button, Form, Image } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate, Navigate} from "react-router-dom"
import supabase from '../../../config/supabaseClient'
const VehicleCard = (props) => {
    const [vehicle, setVehicle] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        const fetchVehicle = async () => {
            try {
                const { data, error } = await supabase
                    .from('vehicle')
                    .select('*')
                    .eq('user_id', props.user_id)
                if (error) throw error
                setVehicle(data[0])
                setLoading(false)
            } catch (error) {
                setError(error.message)
            }
        }
        fetchVehicle()
    }, [])
    if (loading) return <h5 className='text-center'>Loading...</h5>
    if (error) return <h5 className='text-center'>{error}</h5>
    if (vehicle) return (
            <Card className="h-100 shadow">
                <Card.Body className='text-start'>
                <Card.Title>Vehicle</Card.Title>
                    <Card.Text>Registration Number: {vehicle.reg}</Card.Text>
                    <Card.Text>Make: {vehicle.make}</Card.Text>
                    <Card.Text>Model: {vehicle.model} </Card.Text>
                </Card.Body>
            </Card>
    ) ;else return (
        <Card className="h-100">
            <Card.Body className='text-center'>
            <Card.Title>Vehicle</Card.Title>
                <Card.Text>You have not added a vehicle</Card.Text>
                <Button className='m-2' variant="primary" onClick={() => navigate('/driver/addvehicle')}>
                    Add Vehicle
                </Button>
            </Card.Body>
        </Card>
    )
}

export default VehicleCard

