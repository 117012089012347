import { Container, Row, Col, Card, Button, Form, Spinner, Modal, Alert, Stack } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import moment from 'moment/moment'

const CreateChecklistModal = (props) => {
    const { profile, session } = useContext(UserContext)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [allUsersChecked, setAllUsersChecked] = useState(false)
    const [allChecked, setAllChecked] = useState(false)
    const [vehicle_reg, setVehicleReg] = useState('')
    const [created, setCreated] = useState(moment().format('YYYY-MM-DD'))
    const [defects, setDefects] = useState(null)
    const [formErrors, setFormErrors] = useState(null)
    const [selectedUser, setSelectedUser] = useState()
    const [show, setShow] = useState(false)
    const navigate = useNavigate()

    function checkAllBoxes() {
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        checkboxes.forEach((checkbox) => {
            if (checkbox.name !== 'allUsers') {
                checkbox.checked = true
            }
            // checkbox.checked = true
        })
        setAllChecked(true)
    }
    const handleCheckboxes = (e) => {
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        const checked = []
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked && checkbox.name !== 'allUsers') {
                checked.push(checkbox.value)
            }
        })
        if (checked.length === checkboxes.length) {
            setAllChecked(true)
        } else {
            setAllChecked(false)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        const checklist = {}
        checkboxes.forEach((checkbox) => {
            if (checkbox.name !== 'allUsers') {
                checklist[checkbox.name] = checkbox.checked
            }
        })
        if (allUsersChecked) {
            supabase
                .from('user_profiles')
                .select('user_id, user_simple_id, vehicle(reg)')
                .filter('isadmin', 'eq', false)
                .filter('active', 'eq', true)
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    } else {
                        data.forEach((user) => {
                            if (user.vehicle.reg === null) {
                                return
                            }
                            // check if checklist exists for this user and date
                            supabase
                                .from('checklist')
                                .select('id')
                                .eq('user_id', user.user_id)
                                .eq('created_at', created)
                                .then(({ data, error }) => {
                                    if (error) {
                                        console.log(error)
                                    }
                                    else {
                                        if (data.length > 0) {
                                            return
                                        } else {
                                            // insert checklist
                                            supabase
                                                .from('checklist')
                                                .insert([
                                                    {
                                                        user_id: user.user_id,
                                                        vehicle_reg: user.vehicle.reg,
                                                        created_at: created,
                                                        checklist: checklist,
                                                        defects: defects
                                                    },
                                                ])
                                                .then(({ error }) => {
                                                    if (error) {
                                                        console.log(error)
                                                    }
                                                })
                                        }
                                    }
                                })
                        })
                        setLoading(false)
                        setAllUsersChecked(false)
                        setSelectedUser(null)
                        setVehicleReg('')
                        setCreated(moment().format('YYYY-MM-DD'))
                        setDefects(null)
                        props.refresh()
                        setShow(false)
                    }
                })
        } else {
            supabase
                .from('checklist')
                .select('id')
                .eq('user_id', selectedUser.user_id)
                .eq('created_at', created)
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    }
                    else {
                        console.log(data)
                        if (data.length > 0) {
                            alert('Checklist already exists for this date')
                            return
                        } else {
                            supabase
                                .from('checklist')
                                .insert([
                                    {
                                        user_id: selectedUser.user_id,
                                        vehicle_reg: vehicle_reg,
                                        created_at: created,
                                        checklist: checklist,
                                        defects: defects
                                    },
                                ])
                                .then(({ error }) => {
                                    if (error) {
                                        console.log(error)
                                    }
                                    else {
                                        setLoading(false)
                                        setAllUsersChecked(false)
                                        setSelectedUser(null)
                                        setVehicleReg('')
                                        setCreated(moment().format('YYYY-MM-DD'))
                                        setDefects(null)
                                        props.refresh()
                                        setShow(false)
                                    }
                                })
                        }
                    }
                })
        }


    }
    const getReg = (id) => {
        supabase
            .from('vehicle')
            .select('reg')
            .eq('user_id', id)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setVehicleReg(data[0].reg)
                }
            })
    }
    useEffect(() => {
        supabase
            .from('user_profiles')
            .select('*')
            .filter('isadmin', 'eq', false)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    if (data.length === 0) {
                        setError('No users found')
                    } else {
                        setUsers(data)
                    }
                }
            })
    }, [])
    return (
        <>
            <Button variant='success' className='m-1' onClick={() => setShow(true)}>Add Checklist</Button>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show} onHide={() => { setShow(false); setLoading(false) }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Checklist
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formAllUser">
                            <Form.Label>Select All Users</Form.Label>
                            <Form.Check type="checkbox" name="allUsers" label="All Users" onChange={(e) => { setAllUsersChecked(e.target.checked); setSelectedUser(null) }} />
                        </Form.Group>
                        {!allUsersChecked ?
                            <>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Select User</Form.Label>
                                    <Form.Select onChange={(e) => { setSelectedUser(e.target.selectedOptions[0].attributes['akey'].value); getReg(e.target.selectedOptions[0].attributes['akey'].value) }}>
                                        <option>Select from list</option>
                                        {
                                            users.map((user) => {
                                                return (
                                                    <option key={user.user_id} akey={user.user_id} variant='outline-primary' className='m-1' onSelect={() => console.log('select')} onClick={(e) => setSelectedUser(user)}>{user.fname} {user.lname}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="vehicleReg">
                                    <Form.Label>Vehicle Registration</Form.Label>
                                    <Form.Control type="text" placeholder="Enter vehicle registration" defaultValue={vehicle_reg} onChange={(e) => setVehicleReg(e.target.value)} />
                                </Form.Group>
                            </>
                            : null
                        }
                        <Form.Group className="mb-3" controlId="date">
                            <Form.Label>Date</Form.Label>
                            <Form.Control type="date" placeholder="Enter date"
                                defaultValue={created} onChange={(e) => setCreated(e.target.value)}
                                max={moment().format('YYYY-MM-DD')}
                            />
                        </Form.Group>
                        <Button className='my-3' variant="outline-primary" onClick={checkAllBoxes}>
                            Mark all as complete
                        </Button>
                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" name="Body Damage" label="Body Damage" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Windscreen" label="Windscreen" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Side / rear windows" label="Side / rear windows" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Door functions" label="Door functions" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Wheel nuts" label="Wheel nuts" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Tyres" label="Tyres" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Oil level" label="Oil level" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Water level" label="Water level" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Mirrors" label="Mirrors" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Fuel level" label="Fuel level" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Wiper & washers" label="Wiper & washers" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Indicators" label="Indicators" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Side / head lights" label="Side / head lights" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Interior lights" label="Interior lights" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Brake lights" label="Brake lights" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Steering" label="Steering" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Brakes" label="Brakes" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Seat belts" label="Seat belts" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Horn" label="Horn" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Fluid leaks" label="Fluid leaks" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Exhast" label="Exhast" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Fire extinguisher" label="Fire extinguisher" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="First aid box" label="First aid box" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Door signs" label="Door signs" onChange={(e) => handleCheckboxes(e)} />
                            <Form.Check type="checkbox" name="Taxi plates" label="Taxi plates" onChange={(e) => handleCheckboxes(e)} />
                        </Form.Group>
                        {!allChecked ?
                            <Form.Group className="mb-3" controlId="defects">
                                <Form.Label>Defects</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(e) => setDefects(e.target.value)} placeholder='Please add defect details ' />
                            </Form.Group> : null
                        }
                        {formErrors ?
                            <Alert variant='danger'>
                                {formErrors}
                            </Alert> : null
                        }
                        <Stack gap={1} className='my-3'>
                            <Button variant='secondary' onClick={() => setShow(false)}>Close</Button>
                            <Button className='my-3 float-end' variant="primary" type='submit'>
                                Submit
                            </Button>
                        </Stack>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateChecklistModal