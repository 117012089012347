import { Spinner, Row, Col, Card, Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../config/supabaseClient'
import { UserContext } from '../../App2'
import DocImageModal from './DocImageModal'
import moment from 'moment/moment'
import DocEditModal from './docs/DocEditModal'
const FilterableTable = require('react-filterable-table');

const ViewDocsAdmin = (props) => {
    const { profile, session } = useContext(UserContext)
    const navigate = useNavigate()
    const [res, setRes] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [doc, setDoc] = useState(null)
    const [loading, setLoading] = useState(true)
    const renderModalButton = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => { setShowEditModal(true); setDoc(props.record) }}>
                    View
                </Button>
                <Button variant='primary' className='mx-2' onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'docs' } })}>
                    View all driver documents
                </Button>
            </div>

        )
    }
    const renderExpiry = (props) => {
        let startDate = moment(props.record.expiry)
        let reminderdays = moment(props.record.document_types.reminder_days)
        let endDate = moment()
        let duration = moment.duration(startDate.diff(endDate))
        let days = duration.asDays()
        let reminder = moment.duration(reminderdays.diff(endDate))
        let reminderdaysleft = reminder.asDays()



        if (days < 0) {
            return (
                <div className='bg-danger text-light'>
                    {props.record.expiry ? props.record.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else if (days < reminderdays) {
            return (
                <div className='bg-warning'>
                    {props.record.expiry ? props.record.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else {
            return (
                <div>
                    {props.record.expiry ? props.record.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        }
    }
    const renderName = (props) => {
        return (
            <div>
                {props.record.user_profiles.fname} {props.record.user_profiles.lname}
            </div>
        )
    }
    const fields = [
        { name: 'user_profiles.user_simple_id', displayName: "Driver ID", inputFilterable: true, exactFilterable: false, sortable: true },
        { name: 'user_profiles.fname', displayName: "Driver Name", inputFilterable: true, exactFilterable: true, sortable: true, render: renderName },
        { name: 'document_types.doc_name', displayName: "Document Name", inputFilterable: true, exactFilterable: true, sortable: true },
        { name: 'expiry', displayName: "Expiry Date", inputFilterable: true, exactFilterable: true, sortable: true, render: renderExpiry },
        { name: 'id', displayName: "Actions", inputFilterable: false, exactFilterable: false, sortable: false, render: renderModalButton }
    ];
    const fetchDocs = async () => {
        await supabase
            .from('documents')
            .select('*, user_profiles(*), document_types(*)')
            .filter('active', 'eq', true)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setRes(data)
                    setTimeout(() => {
                        setLoading(false)
                    } , 500)
                }
            })
    }
    useEffect(() => {
        fetchDocs()
    }, [])
    return (
        <div>
            <Row className='p-2 py-3'>
                <Col sm={8}>
                    <h1 className='text-start'>Expiring Documents</h1>
                </Col>
                <Col sm={4} className='text-end'>
                <Button variant="info" className='m-2' onClick={() => {fetchDocs(); setLoading(true)}}>
                    { loading ?<Spinner animation="border" variant="dark" size='sm' /> :
                    <>Refresh <i className="fa fa-refresh" aria-hidden="true" ></i></>}
                </Button>
                </Col>
            </Row>
            {showEditModal ? <DocEditModal item={doc} show={showEditModal} onHide={() => { setShowEditModal(false); fetchDocs(); setDoc(null) }} /> : null}
            <FilterableTable
                namespace="Documents"
                initialSort="expiry"
                initialSortDir={true}
                data={res ? res : []}
                fields={fields}
                topPagerVisible={false}
                noRecordsMessage="There are no documents to display"
                noFilteredRecordsMessage="No documents match your filters!"
                tableClassName="table table-striped table-bordered table-hover"
            />
        </div>
    )
}

export default ViewDocsAdmin
