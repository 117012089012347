import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'

const AddVehicle = () => {
    const navigate = useNavigate()
    const {profile, session} = useContext(UserContext)

    const [vehicle_reg, setVehicleReg] = useState('')
    const [created, setCreated] = useState('')
    const [make, setMake] = useState('')
    const [model, setModel] = useState('')
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        supabase
            .from('vehicle')
            .insert([
                {
                    user_id: session.user.id,
                    reg: vehicle_reg,
                    make: make,
                    model: model,
                },
            ])
            .then(({error}) => {
                if (error) {
                    console.log(error)
                }
                else {
                    navigate('/')
                }
            })


    }

    return (
        <Container>
            <Card className='my-4'>
                <Card.Body>
                    <Card.Title>Add Vehicle</Card.Title>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="vehicleReg">
                            <Form.Label>Vehicle Registration</Form.Label>
                            <Form.Control type="text" placeholder="Enter vehicle registration" value={vehicle_reg} onChange={(e) => setVehicleReg(e.target.value.toUpperCase())} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="make">
                            <Form.Label>Make</Form.Label>
                            <Form.Control type="text" placeholder="Enter vehicle make" onChange={(e) => setMake(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="model">
                            <Form.Label>Model</Form.Label>
                            <Form.Control type="text" placeholder="Enter vehicle model" onChange={(e) => setModel(e.target.value)} />
                        </Form.Group>
                        <Button className='my-3 float-end' variant="primary" type='submit'>
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default AddVehicle