import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import ImageModal from './ImageModal'
import moment from 'moment/moment'

const ViewDocs = (props) => {
    const { profile, session } = useContext(UserContext)
    const navigate = useNavigate()
    const [res, setRes] = useState(null)
    const [showImageModal, setShowImageModal] = useState(false)
    const [image_urls, setImageUrls] = useState(null)
    const [filetype, setFiletype] = useState('')
    const [files, setFiles] = useState(null)
    useEffect(() => {
        supabase
            .from('documents')
            .select('*, user_profiles(*), document_types(*)')
            .eq('user_id', session.user.id)
            .filter('active', 'eq', true)
            .order('expiry', { ascending: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setRes(data)
                }
            })
    }, [])
    return (
        <>
            {showImageModal ? <ImageModal files={files} file_type={filetype} show={showImageModal}  onHide={() => { setShowImageModal(false); setImageUrls(null) }} /> : null}
            <Container>
                <h1 className='display-4 text-center my-3'>Documents</h1>
                <Table striped bordered hover className='shadow'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Expiry date</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {res && res.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.document_types?.doc_name}</td>
                                    {
                                        item.expiry && moment(item.expiry).isBefore(moment()) ?
                                            <td className='bg-danger text-light'>{item.expiry ? item.expiry.split('-').reverse().join('-') : null}</td>
                                            :
                                            <td>{item.expiry ? item.expiry.split('-').reverse().join('-') : null}</td>
                                    }
                                    {
                                        item.doc_notes ?
                                            <td>{item.doc_notes}</td>
                                            :
                                            <td></td>
                                    }
                                    <td>
                                        <Button onClick={() => { setFiles(item.files); setShowImageModal(true); setFiletype(item.file_type) }}>
                                            View
                                        </Button>
                                    </td>
                                </tr>

                            )
                        })}

                    </tbody>
                </Table>
            </Container>
        </>
    )
}

export default ViewDocs
